.bgSideBar {
    background-color: #002C4B;
    height: 100vh;
    width: 60px;
    padding: 0px 8px;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    position: fixed;
    z-index: 1;
    & a {
        width: 100%;
        color: black;
        position: relative;
        text-decoration: none;
    }

    .dropdownItem {
        position: absolute;
        left: 50px;
        top: 0;
        padding: 20px;
        background: #0f1015;
        box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
        border-radius: 6px;
        .LinkDropDown {
            display: flex;
            flex-direction: column;
            padding: 8px 16px;
            color: black !important;
            text-decoration: none;
            &:hover {
                color: lightslategrey;
            }
        }
        .dropdownItemSubMenu {
            position: absolute;
            left: 105px;
            top: 0;
            padding: 20px;
            background: red;
            box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
            border-radius: 6px;
        }
    }

    .changeColor {
        color: white;
        &:hover {
            color: #2E89E4;
        }
    }
}