.bodyCircle {
    background-color: #002C4B;
    position: fixed;
    right: 30px;
    bottom: 30px;
    padding: 20px;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &:hover {
        background-color: rgb(55, 110, 181);
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .iconAdd {
        color: white;
    }
}