.btnDownload {
    height: fit-content;
    background: none;
    border: 1px solid lightgray !important;
    padding: 8px 16px;
    margin-top: 16px;
    border-radius: 6px;
    &:hover {
        background-color: lightgray;
        border: 1px solid lightgray !important;
    }
}