.usrClass {
    height: 36px;
    border-radius: 50px;
}
.dropInfo {
    padding: 8px 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white;
    border-radius: 6px;
    min-width: 150px;
    text-align: center;
    top: 45px;
    right: 0;
    .btnDropDown {
        padding: 8px 16px;
        text-decoration: none;
        font-size: 14px;
        background-color: white;
        color: black;
        border: none;
        &:hover {
            padding: 8px 16px;
            text-decoration: none;
            font-size: 14px;
            background-color: lightcyan;
            color: black;
        }
    }
}