.subtitle {
    color: gray;
}
.mt-20 {
    margin-top: 20px !important;
}
.inputs-forms {
    display: block;
    width: 100%;
    padding: 8px 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-size: 0.9rem !important;
    border: none !important;
    background-color: #f7f9fb !important;
    &:hover {
        box-shadow: 0 1px 6px rgba(32,33,36,.28);
        border-color: rgba(223,225,229,0);
    }
    &:focus {
        box-shadow: 0 1px 6px rgba(32,33,36,.28);
        outline: none !important;
    }
}
.btnEdit{
    padding: 8px 16px;
    background-color: #002C4B;
    width: -moz-fit-content;
    min-width: fit-content;
    height: fit-content;
    color: white;
    border-radius: 6px;
    font-size: 0.875rem;
    &:hover {
        background-color: rgb(55, 110, 181);
    }
}