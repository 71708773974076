* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat";
  
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
  
    li {
      list-style-type: none;
    }
  
    .d-flex {
      display: flex;
    }

    .justify-content-space-between {
      justify-content: space-between;
    }

    .justify-content-space-around {
      justify-content: space-around;
    }

    .bt-1 {
      border-top: 1px solid lightblue !important;
    }

    .p-0 {
      margin: 0;
    }

    .justify-content-center {
      justify-content: center;
    }

    .h-100vh {
      height: 100vh !important;
    }
  
    .column {
      flex-direction: column;
    }
  
    .align-items-center {
      align-items: center;
    }
  
    .logo {
      min-width: 140px;
    }
  
    .p-16 {
      padding: 8px 16px;
    }
  
    .ml {
      margin-left: 6px;
    }
  
    .mainPage {
      background-color: white;
      height: 100vh;
      margin-left: 60px;
    }
  
    .mb-1 {
      margin-bottom: 16px !important;
    }
  
    .ml-1 {
      margin-left: 8px;
    }
    .ml-60 {
    margin-left: 60px;
  }
    
    .cursorPointer {
      cursor: pointer;
    }
  
    .relative {
      position: relative;
    }
  
    .absolute {
      position: absolute;
    }
  
    a {
      text-decoration: none;
    }

    @font-face {
      font-family: 'Montserrat-SemiBold';
      src: local('Montserrat-SemiBold'), url(../src/assets/fonts/montserrat-v24/Montserrat-SemiBold.ttf) format('truetype');
      }
      
      @font-face {
      font-family: 'Montserrat';
      src: local('Montserrat'), url(../src/assets/fonts/montserrat-v24/Montserrat-VariableFont_wght.ttf) format('truetype');
      }
  
    h1 {
      font-size: 50px !important;
      font-family: "Montserrat";
  }
  
  h2 {
      font-size: 38px !important;
      font-weight: 600 !important;
      font-family: "Montserrat";
  }
  
  h3 {
      font-size: 28px;
      font-weight: 600 !important;
      font-family: "Montserrat";
  }
  
  h4 {
      font-size: 20px !important;
      font-weight: 600 !important;
      font-family: "Montserrat";
  }
  
  h5 {
      font-size: 16px !important;
      font-family: "Montserrat";
  }
  
  p {
      font-size: 0.875rem !important;
      font-family: "Montserrat";
  }
  
  span {
    font-size: 14px !important;
    font-family: "Montserrat";
  }

  small {
    font-size: 0.75rem !important;
    font-family: "Montserrat";
  }

  button {
    font-size: 14px !important;
    font-family: "Montserrat";
  }
  }
  
  