.imgbg {
    background-image: url(../../assets/img/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-position: center;

    .bodyform {
        padding: 20px;
        .inputs-form {
            -webkit-appearance: none;
            appearance: none;
            background-clip: padding-box;
            background-color: #f7f9fb;
            border: none;
            border-radius: 0.25rem;
            box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
            color: #212529;
            display: block;
            font-size: 1rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 20px;
            padding: 8px 16px;
            width: 100%;
            &:focus {
                outline: none;
            }
        }
        .txt {
            color: white;
            text-align: center;
        }
        .txt-title {
            color: white;
            text-align: center;
            margin-bottom: 60px;
        }
    }
    .btnLogin {
        margin-top: 32px;
        width: 100%;
        background-color: white;
        color: black;
        padding: 0.5rem 0.75rem;
        border-radius: 0.25rem;
        border: none;
        cursor: pointer;
        &:hover {
            width: 100%;
            background-color: #3BAFD0;
            color: white;
            padding: 0.5rem 0.75rem;
            border-radius: 0.25rem;
            border: none;
            cursor: pointer;
        }
    }
}