.containerSettings {
    display: flex;
    position: relative;
    max-width: 100% !important;
    height: 100vh;
    background: #f1f1f1;
    word-break: break-all;

    .bloc-tabs {
        display: flex;
        flex-direction: column;

        .tabs {

            padding: 15px;
            text-align: center;
            width: 10rem;
            cursor: pointer;
            box-sizing: content-box;
            position: relative;
            outline: none;
            transition: all .5s;
        }

        .active-tabs {
            background: #002C4B;
            color: white;
            transition: all .5s;
            border-bottom: 1px solid transparent;
        }

        .active-tabs::before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            right: 0%;
            transform: translateX(-50%);
            width: .2rem;
            height: 51px;
            background: rgb(88, 147, 241);
        }
    }

    button {
        border: none;

    }

    .content-tabs {
        width: 100%;

        .content {
            background: white;
            padding: 30px;
            width: 100%;
            height: 100vh;
            display: none;
        }

        .content h2 {
            padding: 0px 0 5px 0px;
        }

        .content hr {
            width: 100px;
            height: 2px;
            background: #222;
            margin-bottom: 5px;
        }

        .content p {
            width: 100%;
            height: 100%;
        }

        .active-content {
            display: block;
        }
    }
}