.insertDoc {
    margin-top: 35px;
    display: flex;
    flex-direction:column ;
    #file {
        display: none;
    }
    #file-label {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #002C4B;
    width:fit-content;
    color: white;
    border-radius: 6px;
    font-size: 0.875rem;
    cursor: pointer;
    &:hover {
        background-color: rgb(55, 110, 181);
    }
    }
    .btnUpload {
    padding: 8px 16px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 6px;
    &:hover {
        padding: 8px 16px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: gray;
    color: white;
    }
    }
    .sm-file {
        margin-top: 8px;
    }
    .btnAddFile {
    border: none;

    }
}