.mainPageFactory {
    padding-left: 90px;
    padding-right: 30px;
    padding-top: 30px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #003b65;
    .headFactoryGroup {
        color: #002C4B;
    }
}