table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    .colorHead {
        background-color: #dddddd;
    }
    td, th {
        text-align: left;
        padding: 8px;
      }
      
      .tableTr:nth-child(even) {
        background-color: #f1f1f1;
      }
      .btnDell{
        background: none;
        color: #002C4B;
        &:hover {
          color: gray;
        }
      }
}
  
  