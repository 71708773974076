.headBody {
    color: black;
    width: 100%;
    .inDown {
        justify-content: space-around;
        .titleModal {
            color: #002C4B !important;
        }
        .inputBoxsCoordinates {
            width: 100%;
        }
    }
    .blue {
        color: #002C4B !important;
    }
    .btnDeleteUser {
        padding: 8px 16px;
        background-color: rgb(240, 35, 35);
        width: -moz-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        color: white;
        border-radius: 6px;
        border: none;
        font-size: 0.875rem;
        &:hover {
            background-color: rgb(238, 82, 82);
        }
    }
    .btnCancel {
        padding: 8px 16px;
        background-color: lightgray;
        width: -moz-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        color: black;
        border-radius: 6px;
        border: none;
        font-size: 0.875rem;
        &:hover {
            background-color: gray;
            color: white;
        }
    }
}