.table {
    width: 100%;
    border-collapse: collapse;
    border-collapse: separate;
    table-layout: fixed;
    border-spacing: 6px;
    border-style: none !important;
    text-align: center;
    .tabletd {
        background-color: #c54f42;
        border-radius: 6px;
        color: white;
        text-align: center;
        padding: 8px 0px 8px 0px;
        border-style: none !important;
        &:hover {
            cursor: pointer;
            background-color: gray;
        }
        
      }
    .titlecell {
        font-weight: 600;
        padding-bottom: 8px;
    }
    .cellOther {
        border-top: 1px solid white;
        padding-top: 8px;
    }
}
  